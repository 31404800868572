import { CommentLikeResponseType } from 'types/api/commentLikeResponseType';
import { CommentsResponseType } from 'types/api/commentsResponseType';

export interface StateInterface {
  commentReplys?: Array<CommentsResponseType>;
  replyToReply: Array<CommentsResponseType>;
  commentId?: number;
  newCommentReply?: CommentsResponseType;
  commentContent?: string;
  setReplysToComments: (commentReplys: Array<CommentsResponseType>) => void;
  setReplyToReply: (commentReplys: Array<CommentsResponseType>) => void;
  updateReplysToComments?: (newComment: CommentsResponseType) => void;
  updateUserReplysToComments?: (
    commentContent: string,
    commentId: number,
    isCreateReply?: boolean,
    isDeleteReply?: boolean
  ) => void;
  getCommentsById: (commentId: number) => Array<CommentsResponseType>;
  deleteUserReplysToComments: (commentId: number) => Array<CommentsResponseType>;
  createLikeForReplysToComments: (
    commentId: number,
    likeId: number
  ) => Array<CommentsResponseType>;
  updateLikeForReplysToComments: (commentId: number) => Array<CommentsResponseType>;
}

/**
 * @example
 *
 * ```js
 * import { useStore } from 'core';
 *
 * const { lists, setLists, getListsOfMinistryById } = useStore('lists')('lists', 'setLists', 'getListsOfMinistryById');
 * const listsOfThisMinistry = getListsOfMinistryById(ministryId); // ministryId = 8
 * console.log(listsOfThisMinistry) // [{ id: 1, title: 'Some List', ministriesIds: [1, 2, 8] }, { id: 5, title: 'Another List', ministriesIds: [8, 12] }]
 * ```
 */
export const CommentsReplysModel = (
  set: (arg0: (state: StateInterface) => StateInterface) => any,
  get: () => StateInterface
) =>
  ({
    commentReplys: [],
    replyToReply: [],
    commentId: 0,
    newComment: [],
    commentContent: '',

    setReplysToComments: (commentReplys: Array<CommentsResponseType>) => {
      set(state => ({ ...state, commentReplys: commentReplys }));
    },
    setReplyToReply: (replyToReply: Array<CommentsResponseType>) => {
      set(state => ({ ...state, replyToReply: replyToReply }));
    },
    updateReplysToComment: (newReplyToComment: CommentsResponseType) => {
      const comments = get().commentReplys;
      comments?.unshift(newReplyToComment);
      set(state => ({ ...state, comments }));
    },
    updateUserReplysToComment: (
      commentContent: string,
      commentId: number,
      isCreateReply?: boolean,
      isDeleteReply?: boolean
    ) => {
      const comments = get().commentReplys;
      if (!comments || comments?.length <= 0) throw new Error('There are no lists');

      const updatedComment = comments.map(item => {
        if (item.id === commentId && commentContent.length !== 0) {
          return { ...item, content: commentContent };
        } else if (item.id === commentId && isCreateReply) {
          return { ...item, count_responses: item.count_responses + 1 };
        } else if (item.id === commentId && isDeleteReply) {
          return { ...item, count_responses: item.count_responses - 1 };
        }
        return item;
      });
      set(state => ({ ...state, commentReplys: updatedComment }));
    },
    deleteUserReplysToComment: (commentId: number) => {
      const comments = get().commentReplys;
      if (!comments || comments?.length <= 0) throw new Error('There are no lists');

      const updatedArray = comments.filter(item => item.id !== commentId);
      set(state => ({ ...state, commentReplys: updatedArray }));
    },
    createLikeForReplysToComments: (commentId: number, likeId: number) => {
      const comments = get().commentReplys;
      if (!comments || comments?.length <= 0) throw new Error('There are no lists');
      const updatedComment = comments.map(item => {
        if (item.id === commentId) {
          return {
            ...item,
            is_like: {
              id: likeId,
              value: true,
            },
          };
        }
        return item;
      });
      set(state => ({ ...state, commentReplys: updatedComment }));
    },
    updateLikeForReplysToComments: (commentId: number) => {
      const comments = get().commentReplys;
      if (!comments || comments?.length <= 0) throw new Error('There are no lists');
      const updatedComment = comments.map(item => {
        if (item.id === commentId) {
          return {
            ...item,
            is_like: item.is_like
              ? {
                  id: item.is_like.id,
                  value: !item.is_like.value,
                }
              : null,
          };
        }
        return item;
      });
      set(state => ({ ...state, commentReplys: updatedComment }));
    },
  }) as unknown as StateInterface;
