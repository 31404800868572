export interface StateInterface {
  moderationVerdict: string;
  setModerationVerdict: (moderationVerdict: string) => void;
}

export const ModerationVerdictModel = (
  set: (arg0: (state: StateInterface) => StateInterface) => any,
  get: () => StateInterface
) =>
  ({
    moderationVerdict: 'pending',
    setModerationVerdict: (updateModerationVerdict: string) => {
      set(state => ({
        ...state,
        moderationVerdict: updateModerationVerdict,
      }));
    },
  }) as unknown as StateInterface;
