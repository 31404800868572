import { ArticleResponseType } from 'types/api/articleResponseType';

export interface StateInterface {
  infinitiveTapeArray: ArticleResponseType[];
  infinitiveTapeItemId: number;
  setInfinitiveTapeArray: (newArtileData: ArticleResponseType) => void;
  resetInfinitiveTapeArray: () => void;
  setInfinitiveTapeItemId: (newInfinitiveTapeItemId: number) => void;
}

export const InfinitiveTapeModel = (
  set: (arg0: (state: StateInterface) => StateInterface) => any,
  get: () => StateInterface
) =>
  ({
    infinitiveTapeArray: [],
    setInfinitiveTapeArray: (newArtileData: ArticleResponseType) => {
      const infinitiveTapeArray = get().infinitiveTapeArray;
      infinitiveTapeArray.push(newArtileData);
      set(state => ({ ...state, infinitiveTapeArray }));
    },
    setInfinitiveTapeItemId: (newInfinitiveTapeItemId: number) => {
      set(state => ({ ...state, infinitiveTapeItemId: newInfinitiveTapeItemId }));
    },
    resetInfinitiveTapeArray: () => {
      set(state => ({ ...state, infinitiveTapeArray: [] }));
    },
  }) as unknown as StateInterface;
