import styled from 'styled-components';
import { whiteTheme } from 'modules/hairmakeup/styles/themes/whiteTheme';
import { Image } from 'ui/Image';
import { Link } from 'ui/Link';

export const Container = styled(Link)`
  display: block;
  cursor: pointer;
  text-decoration: none;
  max-width: 335px;
  width: 100%;
  margin: 0 auto;
  ${whiteTheme.media.tablet} {
    width: 100%;
    background-color: #fff;
    position: fixed;
    padding: 17px 0;
    top: 0;
    left: 50%;
    z-index: 1004;
    transform: translate(-50%);
  }
  ${whiteTheme.media.mobileL} {
    max-width: 180px;
  }
  ${whiteTheme.media.mobileM} {
    max-width: 130px;
  }
`;

export const Logo = styled(Image).attrs(() => ({
  objectFit: 'scale-down',
}))`
  max-width: 100%;
  width: 100%;
  height: 65px;
  img {
    height: 65px !important;
  }
  ${whiteTheme.media.tablet} {
    order: 1;
    max-width: 260px;
    height: 50px;
    margin: 0 auto;
    img {
      height: 50px !important;
    }
  }
  ${whiteTheme.media.mobileM} {
    max-width: 170px;
    height: 35px;
    img {
      height: 35px !important;
    }
  }
`;
