export type LangObjType = {
  ru: string;
  uk: string;
  en: string;
};

export interface StateInterface {
  langLinks: LangObjType;
  setLangLinks: (langLinks: LangObjType) => void;
}

/**
 * @example
 *
 * ```js
 * import { useStore } from 'core';
 *
 * const { lists, setLists, getListsOfMinistryById } = useStore('lists')('lists', 'setLists', 'getListsOfMinistryById');
 * const listsOfThisMinistry = getListsOfMinistryById(ministryId); // ministryId = 8
 * console.log(listsOfThisMinistry) // [{ id: 1, title: 'Some List', ministriesIds: [1, 2, 8] }, { id: 5, title: 'Another List', ministriesIds: [8, 12] }]
 * ```
 */
export const LangModel = (
  set: (arg0: (state: StateInterface) => StateInterface) => any,
  get: () => StateInterface
) =>
  ({
    langLinks: {
      ru: '',
      uk: '',
      en: '',
    },
    setLangLinks: (langLinks: LangObjType) => {
      set(state => ({ ...state, langLinks }));
    },
  }) as unknown as StateInterface;
