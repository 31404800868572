import { create } from 'zustand';

// Models
import {
  CommentsModel,
  StateInterface as CommentsModelStateInterface,
} from 'modules/main/models/comments.model';
import {
  CommentsReplysModel,
  StateInterface as CommentsReplysModelStateInterface,
} from 'modules/main/models/commentsReplys.model';
import {
  ReplyToReplyModel,
  StateInterface as ReplyToReplyModelStateInterface,
} from 'modules/main/models/replyToReply.model';
import {
  ModerationRulesPopUpModel,
  StateInterface as ModerationRulesPopUpModelStateInterface,
} from 'modules/main/models/moderationPopUpOpen.model';
import { LangModel, StateInterface as LangObjType } from 'modules/main/models/lang.model';

import {
  InfinitiveTapeModel,
  StateInterface as InfinitiveTapeType,
} from 'modules/main/models/infinitiveTape.model';
import {
  ModerationVerdictModel,
  StateInterface as ModerationVerdictType,
} from 'modules/main/models/moderationVerdict.model';
import {
  PopUpOpenModel,
  StateInterface as PopUpOpenType,
} from 'modules/main/models/popUpOpen.model';

const models = {
  comments: create<CommentsModelStateInterface>(CommentsModel),
  commentsReplys: create<CommentsReplysModelStateInterface>(CommentsReplysModel),
  replyToReply: create<ReplyToReplyModelStateInterface>(ReplyToReplyModel),
  langModel: create<LangObjType>(LangModel),
  moderationRulesPopUp: create<ModerationRulesPopUpModelStateInterface>(
    ModerationRulesPopUpModel
  ),
  infinitiveTapeModel: create<InfinitiveTapeType>(InfinitiveTapeModel),
  moderationVerdictModel: create<ModerationVerdictType>(ModerationVerdictModel),
  popUpOpenModel: create<PopUpOpenType>(PopUpOpenModel),
};

// const initialStore = createStore(models);

// export const store = initialStore;

type ModelsKeys = keyof ReturnType<() => typeof models>;

export const useStore =
  (modelName: ModelsKeys) =>
  <T>(...fields: Array<keyof T>) =>
    // @ts-ignore
    models[modelName]((state: T) => {
      if (fields.length === 0) return state;
      return fields.reduce((acc, field) => ({ ...acc, [field]: state[field] }), {});
    }) as Partial<T>;
