export interface StateInterface {
  popUpName: string;
  setPopUpName: (updatePopUpName: string) => void;
}

export const PopUpOpenModel = (
  set: (arg0: (state: StateInterface) => StateInterface) => any,
  get: () => StateInterface
) =>
  ({
    popUpName: '',

    setPopUpName: (updatePopUpName: string) => {
      set(state => ({
        ...state,
        popUpName: updatePopUpName,
      }));
    },
  }) as unknown as StateInterface;
