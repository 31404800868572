export interface StateInterface {
  isModerationRulesPopUpOpen: boolean;
  popUpName: string;
  setModerationRulesPopUpOpen: (updateModerationRulesPopUpOpen: boolean) => void;
  setPopUpName: (updatePopUpName: string) => void;
}

export const ModerationRulesPopUpModel = (
  set: (arg0: (state: StateInterface) => StateInterface) => any,
  get: () => StateInterface
) =>
  ({
    isModerationRulesPopUpOpen: false,
    setModerationRulesPopUpOpen: (updateModerationRulesPopUpOpen: boolean) => {
      set(state => ({
        ...state,
        isModerationRulesPopUpOpen: updateModerationRulesPopUpOpen,
      }));
    },
  }) as unknown as StateInterface;
