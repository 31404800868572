import { CommentLikeResponseType } from 'types/api/commentLikeResponseType';
import { CommentsResponseType } from 'types/api/commentsResponseType';

export interface StateInterface {
  replyToReply: Array<CommentsResponseType>;
  commentId?: number;
  newCommentReply?: CommentsResponseType;
  commentContent?: string;
  setReplyToReply: (replyToReply: Array<CommentsResponseType>) => void;
  updateReplyToReply?: (newComment: CommentsResponseType) => void;
  updateUserReply?: (commentContent: string, commentId: number) => void;
  getCommentsById: (commentId: number) => Array<CommentsResponseType>;
  deleteUserReply: (commentId: number) => Array<CommentsResponseType>;
  createLikeForReply: (commentId: number, likeId: number) => Array<CommentsResponseType>;
  updateLikeForReply: (commentId: number) => Array<CommentsResponseType>;
}

/**
 * @example
 *
 * ```js
 * import { useStore } from 'core';
 *
 * const { lists, setLists, getListsOfMinistryById } = useStore('lists')('lists', 'setLists', 'getListsOfMinistryById');
 * const listsOfThisMinistry = getListsOfMinistryById(ministryId); // ministryId = 8
 * console.log(listsOfThisMinistry) // [{ id: 1, title: 'Some List', ministriesIds: [1, 2, 8] }, { id: 5, title: 'Another List', ministriesIds: [8, 12] }]
 * ```
 */
export const ReplyToReplyModel = (
  set: (arg0: (state: StateInterface) => StateInterface) => any,
  get: () => StateInterface
) =>
  ({
    replyToReply: [],
    commentId: 0,
    newComment: [],
    commentContent: '',

    setReplyToReply: (replyToReply: Array<CommentsResponseType>) => {
      set(state => ({ ...state, replyToReply: replyToReply }));
    },
    updateReplyToReply: (newReplyToComment: CommentsResponseType) => {
      const comments = get().replyToReply;
      comments.unshift(newReplyToComment);
      set(state => ({ ...state, comments }));
    },
    updateUserReplyToReply: (commentId: number, commentContent: string) => {
      const comments = get().replyToReply;
      if (!comments || comments?.length <= 0) throw new Error('There are no lists');

      const updatedComment = comments.map(item => {
        if (item.id === commentId) {
          return { ...item, content: commentContent };
        }
        return item;
      });
      set(state => ({ ...state, replyToReply: updatedComment }));
    },
    deleteUserReplyToReply: (commentId: number) => {
      const comments = get().replyToReply;
      if (!comments || comments?.length <= 0) throw new Error('There are no lists');

      const updatedArray = comments.filter(item => item.id !== commentId);
      set(state => ({ ...state, replyToReply: updatedArray }));
    },
    createLikeForReplyToReply: (commentId: number, likeId: number) => {
      const comments = get().replyToReply;
      if (!comments || comments?.length <= 0) throw new Error('There are no lists');
      const updatedComment = comments.map(item => {
        if (item.id === commentId) {
          return {
            ...item,
            is_like: {
              id: likeId,
              value: true,
            },
          };
        }
        return item;
      });
      set(state => ({ ...state, replyToReply: updatedComment }));
    },
    updateLikeForReplyToReply: (commentId: number) => {
      const comments = get().replyToReply;
      if (!comments || comments?.length <= 0) throw new Error('There are no lists');
      const updatedComment = comments.map(item => {
        if (item.id === commentId) {
          return {
            ...item,
            is_like: item.is_like
              ? {
                  id: item.is_like.id,
                  value: !item.is_like.value,
                }
              : null,
          };
        }
        return item;
      });
      set(state => ({ ...state, replyToReply: updatedComment }));
    },
  }) as unknown as StateInterface;
