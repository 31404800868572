import { CommentsResponseType } from 'types/api/commentsResponseType';

export interface StateInterface {
  comments: Array<CommentsResponseType>;
  commentId: number;
  newComment: CommentsResponseType;
  commentContent: string;
  setComments: (comments: Array<CommentsResponseType>) => void;
  updateComments?: (newComment: CommentsResponseType) => void;
  updateUserComment?: (
    commentContent: string,
    commentId: number,
    isCreateReply?: boolean,
    isDeleteReply?: boolean
  ) => void;
  deleteUserComment: (commentId: number) => Array<CommentsResponseType>;
  createLikeForComments: (commentId: number, likeId: number) => void;
  updateLikeForComments: (commentId: number) => Array<CommentsResponseType>;
}

/**
 * @example
 *
 * ```js
 * import { useStore } from 'core';
 *
 * const { lists, setLists, getListsOfMinistryById } = useStore('lists')('lists', 'setLists', 'getListsOfMinistryById');
 * const listsOfThisMinistry = getListsOfMinistryById(ministryId); // ministryId = 8
 * console.log(listsOfThisMinistry) // [{ id: 1, title: 'Some List', ministriesIds: [1, 2, 8] }, { id: 5, title: 'Another List', ministriesIds: [8, 12] }]
 * ```
 */
export const CommentsModel = (
  set: (arg0: (state: StateInterface) => StateInterface) => any,
  get: () => StateInterface
) =>
  ({
    comments: [],
    commentsLike: [],
    commentId: 1,
    newComment: [],
    commentContent: '',

    setComments: (comments: Array<CommentsResponseType>) => {
      set(state => ({ ...state, comments }));
    },
    updateComments: (newComment: CommentsResponseType) => {
      const comments = get().comments;
      comments?.unshift(newComment);
      set(state => ({ ...state, comments }));
    },
    updateUserComment: (
      commentContent: string,
      commentId: number,
      isCreateReply?: boolean,
      isDeleteReply?: boolean
    ) => {
      const comments = get().comments;
      if (!comments || comments?.length <= 0) throw new Error('There are no lists');

      const updatedComment = comments.map(item => {
        if (item.id === commentId && commentContent.length !== 0) {
          return { ...item, content: commentContent };
        } else if (item.id === commentId && isCreateReply) {
          return { ...item, count_responses: item.count_responses + 1 };
        } else if (item.id === commentId && isDeleteReply) {
          return { ...item, count_responses: item.count_responses - 1 };
        }
        return item;
      });

      set(state => ({ ...state, comments: updatedComment }));
    },
    deleteUserComment: (commentId: number) => {
      const comments = get().comments;
      if (!comments || comments?.length <= 0) throw new Error('There are no lists');

      const updatedArray = comments.filter(item => item.id !== commentId);
      set(state => ({ ...state, comments: updatedArray }));
    },
    createLikeForComments: (commentId: number, likeId: number): void => {
      const comments = get().comments;
      if (!comments || comments?.length <= 0) throw new Error('There are no lists');
      const updatedComment = comments.map(item => {
        if (item.id === commentId) {
          return {
            ...item,
            is_like: {
              id: likeId,
              value: true,
            },
          };
        }
        return item;
      });
      set(state => ({ ...state, comments: updatedComment }));
    },
    updateLikeForComments: (commentId: number) => {
      const comments = get().comments;
      if (!comments || comments?.length <= 0) throw new Error('There are no lists');
      const updatedComment = comments.map(item => {
        if (item.id === commentId) {
          return {
            ...item,
            is_like: item.is_like
              ? {
                  id: item.is_like.id,
                  value: !item.is_like.value,
                }
              : null,
          };
        }
        return item;
      });
      set(state => ({ ...state, comments: updatedComment }));
    },
  }) as unknown as StateInterface;
